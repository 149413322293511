import { createRouter, createWebHistory } from 'vue-router'

//HEADER
import Header from '@/components/componentHeader.vue'

//FOOTER
import Footer from '@/components/componentFooter.vue'

//VIEWS
import Home from '@/views/viewHome.vue'
import Goals from '@/views/viewGoals.vue'
import Stadistics from '@/views/viewStadistics.vue'
import Video from '@/views/viewVideo.vue'
import Candidaturas from '@/views/viewCandidaturas.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        components: {
            header: Header,
            default: Home,
            footer: Footer
        }
    },
    {
        path: '/objetivos_del_sistema',
        name: 'objetivos',
        components: {
            header: Header,
            default: Goals,
            footer: Footer
        }
    },
    {
        path: '/estadisticas',
        name: 'estadisticas',
        components: {
            header: Header,
            default: Stadistics,
            footer: Footer
        }
    },
    {
        path: '/video_informativo',
        name: 'video_informativo',
        components: {
            header: Header,
            default: Video,
            footer: Footer
        }
    },
    {
        path: '/candidaturas/:candidato_id',
        name: 'candidaturas',
        components: {
            header: Header,
            default: Candidaturas,
            footer: Footer
        },
        props: true
    }
]

const router = createRouter({mode: 'history', history: createWebHistory(process.env.VUE_APP_BASE_URL), routes })

export default router