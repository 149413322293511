<template>
    <v-footer class="ctr back-light-grey d-flex flex-column">
        <div style="width: 90vw;">
            <v-row>
                <v-col cols="12" class="ctr bold grey medium">
                    Consulte la información proporcionada de manera obligatoria por las candidaturas que participan en el proceso electoral 2023-2024.<br>
                    La información es responsabilidad de los partidos políticos. El instituto apoya únicamente para su difusión.
                </v-col>
            </v-row>
        </div>
        
        <div style="padding-top: 5vh; width: 90vw;">
            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-row class="ctr grey medium">
                        <v-col cols="12" class="bold h-hp">IEES</v-col>
                        <v-col cols="12" class="h-hp">© Derechos reservados</v-col>
                        <v-col cols="!2" class="h-hp">Instituto Electoral del Estado de Sinaloa</v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" class="ctr grey small">
                    <v-row class="ctr grey medium">
                        <v-col cols="12" class="bold h-hp">OFICINA CENTRAL</v-col>
                        <v-col cols="12">
                            Paseo Niños Heroes No.352 Ote. Int.2,<br>Col.Centro, 8000, Culiacán, Sinaloa
                        </v-col>
                        <v-col cols="!2">
                            (667) 715-22-89 | (667) 715-31-82<br>
                            Lada sin costo: 800-50-50-450
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" class="ctr grey small">
                    <v-row class="ctr grey medium">
                        <v-col cols="12" class="bold h-hp">OFICINA ZONA SUR</v-col>
                        <v-col cols="12">
                            Av. Cruz Lizárraga No.604, Local 1,<br>Col.Ferrocarrilera, Plaza Azcona,<br>82010 Mazatlán, Sinaloa
                        </v-col>
                        <v-col cols="!2">
                            (669) 981-27-10<br>
                            Lada sin costo: 800-50-50-450
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" class="ctr grey small">
                    <v-row class="ctr grey medium">
                        <v-col cols="12" class="bold h-hp">OFICINA ZONA NORTE</v-col>
                        <v-col cols="12">
                            Zaragoza 444 Nte., Desp. No.409,<br>entre callejón Rubí y Heriberto Valdez,<br>Edif. San Isidro, 81200, Los Mochis, Sinaloa
                        </v-col>
                        <v-col cols="!2">
                            (668) 817-32-73<br>
                            Lada sin costo: 800-50-50-450
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-footer>
</template>