<template>
    <v-row justify="center">
        <div class="bolder medium grey chip-home" style="width: 50vw;">
            {{ text }} <br>
            {{ cantidades }}
        </div>
    </v-row>

    &nbsp;

    <Bar v-if="chartData.labels"
        id="my-chart-id"
        :options="chartOptions"
        :data="chartData"
    />

</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  props: {
    labels_data: Array,
    datasets_data: Array,
    text: String,
    cantidades: String
  },
  components: { 
    Bar
  },
  data(){
    return{
      chartData: {
        labels: this.labels_data,
        datasets: this.datasets_data
      }
    }
  },
  async mounted(){
    console.log('CREANDO GRAFICO');
    console.log(this.labels_data);
    console.log(this.datasets_data);
  }
}
</script>