<template>
<v-main>
<v-container fluid style="width: 90vw;">
    <v-row justify="space-between">
    <v-col cols="12" sm="12" md="12" lg="8" xl="4">
        <v-row justify="space-between">
        <v-col cols="12" lg="4" xl="2">
            <v-btn @click="window='PM'" class="btn-forms medium" size="x-large">
              PRESIDENCIAS<br>MUNICIPALES
            </v-btn>
        </v-col>

        <v-col cols="12" lg="4" xl="2">
            <v-btn @click="window='MR'" class="btn-forms medium" size="x-large">
              DIPUTACIONES<br>DE MR
            </v-btn>
        </v-col>

        <v-col cols="12" lg="4" xl="2">
            <v-btn @click="window='RP'" class="btn-forms medium" size="x-large">
              DIPUTACIONES<br>DE RP
            </v-btn>
        </v-col>
        </v-row>
    </v-col>
    </v-row>

    <v-window v-model="window" direction="vertical" mandatory>
      <v-window-item value="PM">
        <v-container fluid style="padding-top: 50px;">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4">
              <v-list>
                <v-list-item class="grey bold xl st" v-for="categoria in categorias" :key="categoria.id">
                  <v-btn variant="text" color="#732282" size="large" class="bold large" @click="graphic = categoria.id" :style="categoria.id == graphic ? { backgroundColor: '#732282', color: '#ffffff' } : { backgroundColor: '#ffffff' }">{{ categoria.descripcion }}</v-btn>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="8" v-if="stadistics_pm.registro_curricular">
              <ComponentChart v-if="graphic == 1"
                :labels_data = "stadistics_pm.registro_curricular.labels"
                :datasets_data = "[
                  {data: stadistics_pm.registro_curricular.total, label: 'Totales', backgroundColor: '#732282'},
                  {data: stadistics_pm.registro_curricular.publish, label: 'Publicados', backgroundColor: '#c46ece'}
                ]"
                :text= "'REGISTRO CURRICULAR | PRESIDENCIA MUNICIPAL'"
                :cantidades= "stadistics_pm.registro_curricular.publicados + ' (' + stadistics_pm.registro_curricular.porcentaje + '%) candidaturas publicadas de un total de ' + stadistics_pm.registro_curricular.totales + ' (100%) candidaturas totales'"
              />

              <ComponentChart v-if="graphic == 2"
                :labels_data = "stadistics_pm.grado_academico.labels"
                :datasets_data = "[
                  {data: stadistics_pm.grado_academico.grados, label: 'Grados', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR GRADO ACADÉMICO'"
                :cantidades= "stadistics_pm.grado_academico.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 3"
                :labels_data = "stadistics_pm.rango_edad.labels"
                :datasets_data = "[
                  {data: stadistics_pm.rango_edad.totales, label: 'Rangos de edad', backgroundColor: ['#732282', '#e1bee7', '#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR RANGO DE EDAD'"
                :cantidades= "stadistics_pm.registro_curricular.publicados + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 4"
                :labels_data = "stadistics_pm.genero.labels"
                :datasets_data = "[
                  {data: stadistics_pm.genero.hombres, label: 'Hombres', backgroundColor: '#c46ece'},
                  {data: stadistics_pm.genero.mujeres, label: 'Mujeres', backgroundColor: '#732282'},
                  {data: stadistics_pm.genero.otros, label: 'Otros', backgroundColor: '#e1bee7'}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR GÉNERO'"
                :cantidades= "stadistics_pm.registro_curricular.publicados + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 5"
                :labels_data = "stadistics_pm.rango_salario.labels"
                :datasets_data = "[
                  {data: stadistics_pm.rango_salario.rango_salario, label: 'Nivel de ingresos', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR NIVEL DE INGRESOS'"
                :cantidades= "stadistics_pm.rango_salario.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 6"
                :labels_data = "stadistics_pm.indigena.labels"
                :datasets_data = "[
                  {data: stadistics_pm.indigena.indigenas, label: 'Candidaturas Indígenas', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS INDÍGENAS'"
                :cantidades= "stadistics_pm.indigena.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 7"
                :labels_data = "stadistics_pm.discapacidad.labels"
                :datasets_data = "[
                  {data: stadistics_pm.discapacidad.discapacidad, label: 'Candidaturas con discapacidad', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS CON DISCAPACIDAD'"
                :cantidades= "stadistics_pm.discapacidad.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 8"
                :labels_data = "stadistics_pm.afromexicana.labels"
                :datasets_data = "[
                  {data: stadistics_pm.afromexicana.afromexicana, label: 'Candidaturas afromexicanas', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS AFROMEXICANAS'"
                :cantidades= "stadistics_pm.afromexicana.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 9"
                :labels_data = "stadistics_pm.diversidad.labels"
                :datasets_data = "[
                  {data: stadistics_pm.diversidad.diversidad, label: 'Candidaturas de la diversidad sexual', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS DE LA DIVERSIDAD SEXUAL'"
                :cantidades= "stadistics_pm.diversidad.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 10"
                :labels_data = "stadistics_pm.migrantes.labels"
                :datasets_data = "[
                  {data: stadistics_pm.migrantes.migrantes, label: 'Candidaturas migrantes', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS MIGRANTES'"
                :cantidades= "stadistics_pm.migrantes.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 11"
                :labels_data = "stadistics_pm.jovenes.labels"
                :datasets_data = "[
                  {data: stadistics_pm.jovenes.jovenes, label: 'Candidaturas jóvenes', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS JÓVENES'"
                :cantidades= "stadistics_pm.jovenes.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 12"
                :labels_data = "stadistics_pm.mayores.labels"
                :datasets_data = "[
                  {data: stadistics_pm.mayores.mayores, label: 'Candidaturas mayores', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS MAYORES'"
                :cantidades= "stadistics_pm.mayores.totales + ' candidaturas respondieron de un total de ' + stadistics_pm.registro_curricular.publicados + ' candidaturas publicadas'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <v-window-item value="MR">
        <v-container style="width: 90vw;" fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4">
              <v-list>
                <v-list-item class="grey bold xl st" v-for="categoria in categorias" :key="categoria.id">
                  <v-btn variant="text" color="#732282" size="large" class="bold large" @click="graphic = categoria.id" :style="categoria.id == graphic ? { backgroundColor: '#732282', color: '#ffffff' } : { backgroundColor: '#ffffff' }">{{ categoria.descripcion }}</v-btn>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="8">
              <ComponentChart v-if="graphic == 1"
                :labels_data = "stadistics_mr.registro_curricular.labels"
                :datasets_data = "[
                  {data: stadistics_mr.registro_curricular.total, label: 'Totales', backgroundColor: '#732282'},
                  {data: stadistics_mr.registro_curricular.publish, label: 'Publicados', backgroundColor: '#c46ece'}
                ]"
                :text= "'REGISTRO CURRICULAR | DIPUTACIONES DE MAYORÍA RELATIVA'"
                :cantidades= "stadistics_mr.registro_curricular.publicados + ' (' + stadistics_mr.registro_curricular.porcentaje + '%) candidaturas publicadas de un total de ' + stadistics_mr.registro_curricular.totales + ' (100%) candidaturas totales'"
              />

              <ComponentChart v-if="graphic == 2"
                :labels_data = "stadistics_mr.grado_academico.labels"
                :datasets_data = "[
                  {data: stadistics_mr.grado_academico.grados, label: 'Grados', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR GRADO ACADÉMICO'"
                :cantidades= "stadistics_mr.grado_academico.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 3"
                :labels_data = "stadistics_mr.rango_edad.labels"
                :datasets_data = "[
                  {data: stadistics_mr.rango_edad.totales, label: 'Rangos de edad', backgroundColor: ['#732282', '#e1bee7', '#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR RANGO DE EDAD'"
                :cantidades= "stadistics_mr.registro_curricular.publicados + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 4"
                :labels_data = "stadistics_mr.genero.labels"
                :datasets_data = "[
                  {data: stadistics_mr.genero.hombres, label: 'Hombres', backgroundColor: '#c46ece'},
                  {data: stadistics_mr.genero.mujeres, label: 'Mujeres', backgroundColor: '#732282'},
                  {data: stadistics_mr.genero.otros, label: 'Otros', backgroundColor: '#e1bee7'}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR GÉNERO'"
                :cantidades= "stadistics_mr.registro_curricular.publicados + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 5"
                :labels_data = "stadistics_mr.rango_salario.labels"
                :datasets_data = "[
                  {data: stadistics_mr.rango_salario.rango_salario, label: 'Nivel de ingresos', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR NIVEL DE INGRESOS'"
                :cantidades= "stadistics_mr.rango_salario.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 6"
                :labels_data = "stadistics_mr.indigena.labels"
                :datasets_data = "[
                  {data: stadistics_mr.indigena.indigenas, label: 'Candidaturas Indígenas', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS INDÍGENAS'"
                :cantidades= "stadistics_mr.indigena.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 7"
                :labels_data = "stadistics_mr.discapacidad.labels"
                :datasets_data = "[
                  {data: stadistics_mr.discapacidad.discapacidad, label: 'Candidaturas con discapacidad', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS CON DISCAPACIDAD'"
                :cantidades= "stadistics_mr.discapacidad.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 8"
                :labels_data = "stadistics_mr.afromexicana.labels"
                :datasets_data = "[
                  {data: stadistics_mr.afromexicana.afromexicana, label: 'Candidaturas afromexicanas', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS AFROMEXICANAS'"
                :cantidades= "stadistics_mr.afromexicana.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 9"
                :labels_data = "stadistics_mr.diversidad.labels"
                :datasets_data = "[
                  {data: stadistics_mr.diversidad.diversidad, label: 'Candidaturas de la diversidad sexual', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS DE LA DIVERSIDAD SEXUAL'"
                :cantidades= "stadistics_mr.diversidad.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 10"
                :labels_data = "stadistics_mr.migrantes.labels"
                :datasets_data = "[
                  {data: stadistics_mr.migrantes.migrantes, label: 'Candidaturas migrantes', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS MIGRANTES'"
                :cantidades= "stadistics_mr.migrantes.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 11"
                :labels_data = "stadistics_mr.jovenes.labels"
                :datasets_data = "[
                  {data: stadistics_mr.jovenes.jovenes, label: 'Candidaturas jóvenes', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS JÓVENES'"
                :cantidades= "stadistics_mr.jovenes.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 12"
                :labels_data = "stadistics_mr.mayores.labels"
                :datasets_data = "[
                  {data: stadistics_mr.mayores.mayores, label: 'Candidaturas mayores', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS MAYORES'"
                :cantidades= "stadistics_mr.mayores.totales + ' candidaturas respondieron de un total de ' + stadistics_mr.registro_curricular.publicados + ' candidaturas publicadas'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <v-window-item value="RP">
        <v-container style="width: 90vw;" fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4">
              <v-list>
                <v-list-item class="grey bold xl st" v-for="categoria in categorias" :key="categoria.id">
                  <v-btn variant="text" color="#732282" size="large" class="bold large" @click="graphic = categoria.id" :style="categoria.id == graphic ? { backgroundColor: '#732282', color: '#ffffff' } : { backgroundColor: '#ffffff' }">{{ categoria.descripcion }}</v-btn>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="8">
              <ComponentChart v-if="graphic == 1"
                :labels_data = "stadistics_rp.registro_curricular.labels"
                :datasets_data = "[
                  {data: stadistics_rp.registro_curricular.total, label: 'Totales', backgroundColor: '#732282'},
                  {data: stadistics_rp.registro_curricular.publish, label: 'Publicados', backgroundColor: '#c46ece'}
                ]"
                :text= "'REGISTRO CURRICULAR | DIPUTACIONES DE REPRESENTACIÓN PROPORCIONAL'"
                :cantidades= "stadistics_rp.registro_curricular.publicados + ' (' + stadistics_rp.registro_curricular.porcentaje + '%) candidaturas publicadas de un total de ' + stadistics_rp.registro_curricular.totales + ' (100%) candidaturas totales'"
              />

              <ComponentChart v-if="graphic == 2"
                :labels_data = "stadistics_rp.grado_academico.labels"
                :datasets_data = "[
                  {data: stadistics_rp.grado_academico.grados, label: 'Grados', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR GRADO ACADÉMICO'"
                :cantidades= "stadistics_rp.grado_academico.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 3"
                :labels_data = "stadistics_rp.rango_edad.labels"
                :datasets_data = "[
                  {data: stadistics_rp.rango_edad.totales, label: 'Rangos de edad', backgroundColor: ['#732282', '#e1bee7', '#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR RANGO DE EDAD'"
                :cantidades= "stadistics_rp.registro_curricular.publicados + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 4"
                :labels_data = "stadistics_rp.genero.labels"
                :datasets_data = "[
                  {data: stadistics_rp.genero.hombres, label: 'Hombres', backgroundColor: '#c46ece'},
                  {data: stadistics_rp.genero.mujeres, label: 'Mujeres', backgroundColor: '#732282'},
                  {data: stadistics_rp.genero.otros, label: 'Otros', backgroundColor: '#e1bee7'}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR GÉNERO'"
                :cantidades= "stadistics_rp.registro_curricular.publicados + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 5"
                :labels_data = "stadistics_rp.rango_salario.labels"
                :datasets_data = "[
                  {data: stadistics_rp.rango_salario.rango_salario, label: 'Nivel de ingresos', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS POR NIVEL DE INGRESOS'"
                :cantidades= "stadistics_rp.rango_salario.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 6"
                :labels_data = "stadistics_rp.indigena.labels"
                :datasets_data = "[
                  {data: stadistics_rp.indigena.indigenas, label: 'Candidaturas Indígenas', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS INDÍGENAS'"
                :cantidades= "stadistics_rp.indigena.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 7"
                :labels_data = "stadistics_rp.discapacidad.labels"
                :datasets_data = "[
                  {data: stadistics_rp.discapacidad.discapacidad, label: 'Candidaturas con discapacidad', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS CON DISCAPACIDAD'"
                :cantidades= "stadistics_rp.discapacidad.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 8"
                :labels_data = "stadistics_rp.afromexicana.labels"
                :datasets_data = "[
                  {data: stadistics_rp.afromexicana.afromexicana, label: 'Candidaturas afromexicanas', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS AFROMEXICANAS'"
                :cantidades= "stadistics_rp.afromexicana.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 9"
                :labels_data = "stadistics_rp.diversidad.labels"
                :datasets_data = "[
                  {data: stadistics_rp.diversidad.diversidad, label: 'Candidaturas de la diversidad sexual', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS DE LA DIVERSIDAD SEXUAL'"
                :cantidades= "stadistics_rp.diversidad.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 10"
                :labels_data = "stadistics_rp.migrantes.labels"
                :datasets_data = "[
                  {data: stadistics_rp.migrantes.migrantes, label: 'Candidaturas migrantes', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS MIGRANTES'"
                :cantidades= "stadistics_rp.migrantes.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 11"
                :labels_data = "stadistics_rp.jovenes.labels"
                :datasets_data = "[
                  {data: stadistics_rp.jovenes.jovenes, label: 'Candidaturas jóvenes', backgroundColor: ['#c46ece', '#e1bee7', '#732282']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS JÓVENES'"
                :cantidades= "stadistics_rp.jovenes.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />

              <ComponentChart v-if="graphic == 12"
                :labels_data = "stadistics_rp.mayores.labels"
                :datasets_data = "[
                  {data: stadistics_rp.mayores.mayores, label: 'Candidaturas mayores', backgroundColor: ['#732282', '#e1bee7', '#c46ece']}
                ]"
                :text= "'REGISTRO DE CANDIDATURAS MAYORES'"
                :cantidades= "stadistics_rp.mayores.totales + ' candidaturas respondieron de un total de ' + stadistics_rp.registro_curricular.publicados + ' candidaturas publicadas'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
</v-container>
</v-main>
</template>

<script>
import ComponentChart from '@/components/componentBarChart.vue'

export default {
  name: 'BarChart',
  components: {
    ComponentChart
  },
  data() {
    return {
      stadistics_pm: {},
      stadistics_mr: {},
      stadistics_rp: {},
      categorias: [
        {id: 1, descripcion: 'REGISTRO CURRICULAR'},
        {id: 2, descripcion: 'GRADO ACADÉMICO'},
        {id: 3, descripcion: 'RANGO DE EDAD'},
        {id: 4, descripcion: 'SEXO'},
        {id: 5, descripcion: 'NIVEL DE INGRESOS'},
        {id: 6, descripcion: 'CANDIDATURAS ÍNDIGENAS'},
        {id: 7, descripcion: 'CANDIDATURAS DE DISCAPACIDAD'},
        {id: 8, descripcion: 'CANDIDATURAS AFROMEXICANAS'},
        {id: 9, descripcion: 'CANDIDATURAS DE LA DIVERSIDAD SEXUAL'},
        {id: 10, descripcion: 'CANDIDATURAS DE MIGRANTES'},
        {id: 11, descripcion: 'CANDIDATURAS DE PERSONAS JÓVENES'},
        {id: 12, descripcion: 'CANDIDATURAS DE PERSONAS MAYORES'},
      ],
      window: 'PM',
      graphic: 1
    }
  },
  async beforeCreate(){
    await fetch("/jsons/STADISTICS_PM.json")
    .then((response) => response.json())
    .then((response) => {
        this.stadistics_pm = response;
    });

    await fetch("/jsons/STADISTICS_MR.json")
    .then((response) => response.json())
    .then((response) => {
        this.stadistics_mr = response;
    });

    await fetch("/jsons/STADISTICS_RP.json")
    .then((response) => response.json())
    .then((response) => {
        this.stadistics_rp = response;
    });
  }
}
</script>