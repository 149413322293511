<template>
<div>
    <div style="height: 45px;">
        <v-app-bar color="#732282" :elevation="20" height="105">
            <div style="min-width: 200px;">
                <a href="https://www.ieesinaloa.mx" target="blank">
                    <v-img :src="ieesPath" alt="Instituto Electoral del Estado de Sinaloa"/>
                </a>
            </div>

            <v-container fluid>
                
            </v-container>

            <div style="min-width: 250px;">
                <v-btn icon="mdi-facebook" size="large" href="https://www.facebook.com/ieesinaloa/" target="blank"/>
                <v-btn icon="" size="large" href="https://twitter.com/IEESINALOA" target="blank">
                    <v-icon>
                        <v-img :src="require('@/assets/X_logo.png')" alt="icon"/>
                    </v-icon>
                </v-btn>
                <v-btn icon="mdi-instagram" size="large" href="https://www.instagram.com/ieesinaloa/" target="blank"/>
                <v-btn icon="mdi-youtube" size="large" href="https://www.youtube.com/channel/UCOpCdraKRNYajo3-j_DfjzA" target="blank"/>
            </div>

            <div style="min-width: 200px;">
                <v-img :src="require('@/assets/PROCESO ELECTORAL_2023-2024_v2.jpg')" alt="Proceso Electoral 2023-2024"/>
            </div>

            <div style="min-width: 25px;"></div>

            <div style="min-width: 150px;">
                <v-img :src="conocelesPath" alt="Candidatas y Candidatos, Conóceles"/>
            </div>
        </v-app-bar>
    </div>

    <div>
        <v-tabs stacked class="tabs-header" color="#732282" :elevation="20" height="70" align-tabs="center">
            <v-tab @click="$router.push('/')">
                <v-icon>{{ 'mdi-home' }}</v-icon>
                INICIO
            </v-tab>
            
            <v-tab @click="$router.push('/objetivos_del_sistema')">
                <v-icon>{{ 'mdi-playlist-check' }}</v-icon>
                OBJETIVOS
            </v-tab>
            
            <v-tab @click="$router.push('/estadisticas')">
                <v-icon>{{ 'mdi-chart-bar' }}</v-icon>
                ESTADÍSTICAS
            </v-tab>

            <v-tab @click="$router.push('/video_informativo')">
                <v-icon>{{ 'mdi-motion-play' }}</v-icon>
                VIDEO<br>INFORMATIVO
            </v-tab>

            <v-tab href="https://www.ieesinaloa.mx/?page_id=107" target="blank">
                <v-icon>{{ 'mdi-web' }}</v-icon>
                PLATAFORMAS<br>ELECTORALES
            </v-tab>

            <v-tab href="https://cartografia.ife.org.mx/sige7/?nuevadistritacion" target="blank">
                <v-icon>{{ 'mdi-map-search' }}</v-icon>
                BUSCA TU<br>DISTRITO
            </v-tab>
        </v-tabs>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            ieesPath: require('@/assets/IMG_IEES_WHITE.png'),
            conocelesPath: require('@/assets/CONOCELES.jpg')
        }
    }
}
</script>

<style>
    @import '@/components/styles/styles.css'
</style>