import { createApp } from 'vue'
import App from './App.vue'

//VUE-ROUTER
import router from '@/components/router/index.js'

//VUETIFY
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

//GOOGLE ANALYTICS
import VueGtag from "vue-gtag";

createApp(App).use(router).use(vuetify).use(VueGtag, {config:{ id: "G-K1K10GBH60" }}, router).mount('#app')