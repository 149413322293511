<template>
    <v-main style="padding-top: 10vh;">
    <v-container fluid class="grey">
    <v-row justify="space-between">
        <!--------------------------------------------------------------------------------------------------------------------------------------------->
        <!--CONTENT | PHOTO AREA-->
        <!--------------------------------------------------------------------------------------------------------------------------------------------->
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" style="background-color: #732282;">
            <v-row style="padding-top: 2vh; padding-bottom: 2vh;" justify="center">
            <v-col cols="8" sm="8" md="8" lg="8" xl="10">
                <v-img class="photo" v-if="!candidato_data.fotografia" :src="require('@/assets/DEFAULT_IMG.png')" cover/>
                <v-img class="photo" v-else-if="(typeof candidato_data.fotografia === 'string')" :src="candidato_data.fotografia" cover/>
            </v-col>
            </v-row>

            <v-row justify="center">
                <v-img class="img-ap" :src="candidato_data.logo" cover style="background-color: white;"/>
            </v-row>
    
            <v-row justify="center">
            <v-col class="xxl bold white">
                {{ candidato_data.tipo == 'PROPIETARIO'? candidato_data.nombre_propietario : candidato_data.nombre_suplente }}
            </v-col>
            </v-row>
    
            <v-row v-if="!partido" justify="center">
                <v-img class="img-ap" :src="require('@/assets/DEFAULT_IMG.png')" cover/>
            </v-row>
    
            <v-row v-else justify="center">
                <v-img v-for="p in partido" :key="p.id" class="img-ap" :src="p.logo" cover/>
            </v-row>
    
            &nbsp;
    
            <v-row class="large white" justify="center">
                <v-col cols="12">
                    Dirección de casa de campaña
                </v-col>
                <v-col cols="12">
                    {{ candidato_data.dom_casa_campania? candidato_data.dom_casa_campania : '*****' }}
                </v-col>
            </v-row>
    
            &nbsp;
    
            <v-row class="large white" justify="center">
                <v-col cols="12">
                    Teléfono/s público/s
                </v-col>
                <v-col cols="12">
                    {{ candidato_data.telefono_publico? candidato_data.telefono_publico : '*****' }}
                </v-col>
            </v-row>
    
            &nbsp;
    
            <v-row class="large white" justify="center">
                <v-col cols="12">
                    Correo electrónico
                </v-col>
                <v-col cols="12">
                    {{ candidato_data.correo_publico? candidato_data.correo_publico : '*****' }}
                </v-col>
            </v-row>
    
            &nbsp;
    
            <v-row class="large white" justify="center">
                <v-col cols="12">
                    Página web
                </v-col>
                <v-col cols="12">
                    {{ candidato_data.pagina_web? candidato_data.pagina_web : '*****' }}
                </v-col>
            </v-row>
    
            &nbsp;
    
            <v-row justify="center">
                <v-btn variant="text" icon size="60" v-if="candidato_data.facebook" :href="candidato_data.facebook" target="_blank">
                    <v-icon class="white" size="40">{{ 'mdi:mdi-facebook' }}</v-icon>
                </v-btn>
    
                <v-btn variant="text" icon size="60" v-if="candidato_data.x" :href="candidato_data.x" target="_blank">
                    <v-icon class="white" size="30"><v-img :src="require('@/assets/X_logo.png')"></v-img></v-icon>
                </v-btn>
    
                <v-btn variant="text" icon size="60" v-if="candidato_data.instagram" :href="candidato_data.instagram" target="_blank">
                    <v-icon class="white" size="40">{{ 'mdi:mdi-instagram' }}</v-icon>
                </v-btn>
    
                <v-btn variant="text" icon size="60" v-if="candidato_data.youtube" :href="candidato_data.youtube" target="_blank">
                    <v-icon class="white" size="40">{{ 'mdi:mdi-youtube' }}</v-icon>
                </v-btn>
    
                <v-btn variant="text" icon size="60" v-if="candidato_data.tiktok" :href="candidato_data.tiktok" target="_blank">
                    <v-icon class="white" size="30"><v-img :src="require('@/assets/Tiktok_logo.png')"></v-img></v-icon>
                </v-btn>
            </v-row>
        </v-col>
    
        <!--------------------------------------------------------------------------------------------------------------------------------------------->
        <!--CONTENT | DATA AREA-->
        <!--------------------------------------------------------------------------------------------------------------------------------------------->
        <v-col>
        <v-card style="background-color: transparent;" elevation="0">
        <v-card-text>
            <v-row style="background-color: #c46ece;">
                <v-col class="st xl bold white">
                    &nbsp;
                    <v-icon class="white" size="40" icon="mdi-card-account-details"/>
                    Datos generales
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-col cols="12" lg="6">
                    <v-text-field v-model="candidato_data.nombre_propietario" label="Persona propietaria:" readonly variant="outlined"/>
                </v-col>
                <v-col cols="12" lg="2">
                    <v-text-field
                        :model-value="candidato_data.genero == 'H'? 'HOMBRE' : candidato_data.genero == 'M'? 'MUJER': 'OTRO'"
                        label="Sexo"
                        readonly
                        variant="outlined"
                    />
                </v-col>
                <v-col cols="12" lg="4" class="ctr">
                    <a :href="candidato_data.ficha" target="blank">
                        <v-btn class="btn" size="x-large" prepend-icon="mdi-file">Descargar<br>Ficha</v-btn>
                    </a>
                </v-col>
            </v-row>
    
            <v-row v-if="candidato_data.cargo != 'PRESIDENCIA MUNICIPAL'">
                <v-col cols="12" lg="6">
                    <v-text-field v-model="candidato_data.nombre_suplente" label="Persona suplente:" readonly variant="outlined"/>
                </v-col>
            </v-row>
    
            <v-row>
                <v-col cols="12" lg="3">
                    <v-text-field v-model="candidato_data.candidatura" label="Candidatura" readonly variant="outlined"/>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-text-field v-model="candidato_data.tipo_candidatura" label="Tipo de candidatura" readonly variant="outlined"/>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-text-field v-model="candidato_data.cargo" label="Cargo" readonly variant="outlined"/>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-text-field v-model="candidato_data.localidad" label="Localidad" readonly variant="outlined"/>
                </v-col>
            </v-row>
    
            <v-row style="background-color: #c46ece;">
                <v-col class="st xl bold white">
                    <v-icon class="white" size="40" icon="mdi-certificate"/>
                    Historia profesional y/o laboral
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-textarea :model-value="candidato_data.historia_profesional" readonly variant="outlined"/>
            </v-row>
    
            <v-row style="background-color: #c46ece;">
                <v-col class="st xl bold white">
                    <v-icon class="white" size="40" icon="mdi-certificate"/>
                    Estudios
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-col cols="6">
                    <v-select
                        v-model="candidato_data.grado_academico"
                        :items="grado_academico"
                        item-value="id"
                        item-title="descripcion"
                        label="Grado máximo de estudios"
                        readonly
                        variant="outlined"
                    />
                </v-col>
                <v-col>
                    <v-text-field v-model="candidato_data.grado_status" label="Estado" readonly variant="outlined"/>
                </v-col>
            </v-row>
    
            <v-row style="background-color: #732282;">
                <v-col class="ctr large white">
                    Formación académica: Cursos, Diplomados, Seminarios, etc.
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-textarea :model-value="candidato_data.formacion" readonly variant="outlined"/>
            </v-row>
    
            <v-row style="background-color: #c46ece;">
                <v-col class="st xl bold white">
                    <v-icon class="white" size="40" icon="mdi-certificate"/>
                    Trayectoria política y/o de participación social
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-textarea :model-value="candidato_data.trayectoria" readonly variant="outlined"/>
            </v-row>
    
            <v-row style="background-color: #c46ece;">
                <v-col class="st xl bold white">
                    <v-icon class="white" size="40" icon="mdi-certificate"/>
                    ¿Por qué quiero ocupar un cargo público?
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-textarea :model-value="candidato_data.porque_cargo" readonly variant="outlined"/>
            </v-row>
    
            <v-row style="background-color: #c46ece;">
                <v-col class="st xl bold white">
                    <v-icon class="white" size="40" icon="mdi-certificate"/>
                    Propuestas electorales
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-col cols="12" lg="6">
                <v-row>
                    <v-col cols="12" class="ctr large white" style="background-color: #732282;">
                        Propuesta 1:
                    </v-col>
                    <v-col cols="12">
                        <v-textarea :model-value="candidato_data.propuesta_1" readonly variant="outlined"/>
                    </v-col>
                </v-row>
                </v-col>
    
                <v-col cols="12" lg="6">
                <v-row>
                    <v-col cols="12" class="ctr large white" style="background-color: #732282;">
                        Propuesta 2:
                    </v-col>
                    <v-col cols="12">
                        <v-textarea :model-value="candidato_data.propuesta_2" readonly variant="outlined"/>
                    </v-col>
                </v-row>
                </v-col>
            </v-row>
    
            <v-row style="background-color: #c46ece;">
                <v-col class="st xl bold white">
                    <v-icon class="white" size="40" icon="mdi-certificate"/>
                    Propuesta en materia de género o del grupo en situación de discriminación que representa
                </v-col>
            </v-row>
    
            <v-row style="padding-top: 20px;">
                <v-textarea :model-value="candidato_data.propuesta_genero" readonly variant="outlined"/>
            </v-row>
    
            <v-row style="background-color: #c46ece;" v-if="candidato_data.declaraciones">
                <v-col class="st xl bold white">
                    <v-icon class="white" size="40" icon="mdi-certificate"/>
                    Declaraciones
                </v-col>
            </v-row>
    
            <v-row justify="center" style="padding-top: 20px;" v-if="candidato_data.declaraciones">
                <a :href="candidato_data.declaraciones_doc" target="blank">
                <v-btn class="btn" size="x-large" prepend-icon="mdi-file">Descargar declaraciones</v-btn>
                </a>
            </v-row>
        </v-card-text>
        </v-card>
        </v-col>
    
    </v-row>
    </v-container>
    </v-main>
    </template>
    
    <script>
    //API
    
    export default{
        props: {
            candidato_id: Number
        },
        data() {
            return{
                candidato_data: {},
                candidatura_info: {},
                cargo_info: {},
                localidad_info: {},
                partido: [],
                grado_academico: [
                    {id: 1, descripcion: 'BACHILLERATO'},
                    {id: 2, descripcion: 'LICENCIATURA'},
                    {id: 3, descripcion: 'ESPECIALIDAD'},
                    {id: 4, descripcion: 'MAESTRIA'},
                    {id: 5, descripcion: 'DOCTORADO'},
                    {id: 6, descripcion: 'POSTDOCTORADO'},
                    {id: 7, descripcion: 'OTRO'}
                ]
            }
        },
        methods: {
            link(url){
                window.open(url, '_blank');
            },
            get(candidato){
                return candidato.id == this.candidato_id;
            }
        },
        async mounted(){
            fetch("/jsons/CANDIDATOS.json")
                .then((response) => response.json())
                .then((response) => {
                    const candidato = response.filter(this.get);
                    this.candidato_data = candidato[0];
                })
        }
    }
    </script>