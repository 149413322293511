<template>
<v-main style="margin-top: -35px; padding-bottom: 10vh;">
    <v-img alt="Candidatas_y_Candidatos_Conóceles_Portada.jpg" :src="require('@/assets/CONOCELES_BANNER.jpg')"/>
    <v-container fluid style="width: 100vw;">
        <v-row justify="center">
        <v-col cols="8">
            <div class="bolder medium grey chip-home">
                Consulte la información proporcionada de manera obligatoria por las candidaturas que participan en el proceso electoral 2023-2024.<br>
                La información es responsabilidad de los partidos políticos. El instituto apoya únicamente para su difusión.
            </div>
        </v-col>
        </v-row>
        
        <v-row justify="center">
            <v-col cols="8" class="ctr grey bold medium">
                El sistema te permite hacer búsquedas específicas, o bien, <a class="link" href="https://api-eventos-iees.mx/candidatas_y_candidatos_conoceles_api/storage/app/public/excel/DB_CANDIDATAS_Y_CANDIDATOS_CONOCELES_SINALOA.xlsx" target="blank">exportar la base de datos</a> 
            </v-col>
        </v-row>

        <v-form fast-fail v-model="forms" :on-submit="onSubmit()">
            <v-row justify="center">
            <v-col cols="10">
                <v-row>
                    <v-col cols="12" sm="12" md="4" lg="4">
                    <v-select
                        v-model="busqueda.candidatura"
                        :items="candidaturas"
                        item-title="nombre"
                        item-value="id"
                        label="Candidatura"
                        :rules="[notNull]"
                        @update:model-value="busqueda.localidad = null; consulta_localidades();"
                        :disabled="data"
                    />
                </v-col>

                <v-col>
                    <v-select
                        v-model="busqueda.localidad"
                        :items="localidades"
                        item-title="nombre"
                        item-value="id"
                        label="Localidad"
                        :disabled="!busqueda.candidatura || busqueda.candidatura == 4"
                    />
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                    <v-select v-model="busqueda.partido" :items="actores" item-title="siglas" item-value="id" label="Actor político" clearable>
                        <v-list-item item-title="TODOS"/>
                    </v-select>
                </v-col>
                </v-row>
            </v-col>

            <v-col cols="10">
                <v-row>
                    <v-col cols="12" sm="12" md="4" lg="4">
                        <v-select v-model="busqueda.genero" :items="generos" item-title="nombre" item-value="var" label="Sexo" clearable/>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="4">
                        <v-select v-model="busqueda.rango_edad" :items="rangos_edad" item-title="rango" item-value="id" label="Rango de edad"/>
                    </v-col>

                    <v-col>
                        <v-select v-model="busqueda.grado_academico" :items="grados_academicos" item-title="descripcion" item-value="id" label="Grado académico"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="10">
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-btn class="btn-outlined" variant="outlined" size="x-large" @click="limpiar_campos()">
                            <v-icon>{{ 'mdi-broom' }}</v-icon>
                            Limpiar campos
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn class="btn" size="x-large" @click="consultar()">
                            <v-icon>{{ 'mdi-magnify' }}</v-icon>
                            Consultar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            </v-row>

            <v-row justify="end" v-if="data">
                <v-col cols="8" sm="6" md="4" lg="4">
                    <v-text-field v-model="search" label="Búsqueda" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line/>
                </v-col>
            </v-row>

            <v-row justify="center">
            <v-col cols="10" ref="data_table" v-if="busqueda.candidatura == 2 && data">
                <v-data-table :headers="headers_presidencia" :items="candidatos_busqueda" :search="search">
                    <template #[`item.fotografia`]="{item}">
                        <v-row justify="center">
                            <v-img class="photo-table" v-if="item.fotografia" :src="item.fotografia" style="max-width: 40px;" cover/>
                            <v-img class="photo-table" v-else :src="require('@/assets/DEFAULT_IMG.png')" style="max-width: 40px;" cover/>
                        </v-row>
                    </template>

                    <template #[`item.logo`]="{item}">
                        <v-row justify="center">
                            <v-img class="photo-table" v-if="item.logo" :src="item.logo" style="max-width: 40px;" cover/>
                            <v-img class="photo-table" v-else :src="require('@/assets/DEFAULT_IMG.png')" style="max-width: 40px;" cover/>
                        </v-row>
                    </template>

                    <template #[`item.id`]="{item}">
                        <v-btn class="btn" size="small" icon :href="`/candidaturas/${item.id}`">
                            <v-icon>{{ 'mdi-eye' }}</v-icon>
                            <v-tooltip activator="parent" location="top" open-delay="500">Consultar</v-tooltip>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col cols="10" ref="data_table" v-else-if="data">
                <v-data-table :headers="headers" :items="candidatos_busqueda" :search="search">
                    <template #[`item.fotografia`]="{item}">
                        <v-row justify="center">
                            <v-img class="photo-table" v-if="item.fotografia" :src="item.fotografia" style="max-width: 40px;" cover/>
                            <v-img class="photo-table" v-else :src="require('@/assets/DEFAULT_IMG.png')" style="max-width: 40px;" cover/>
                        </v-row>
                    </template>

                    <template #[`item.logo`]="{item}">
                        <v-row justify="center">
                            <v-img class="photo-table" v-if="item.logo" :src="item.logo" style="max-width: 40px;" cover/>
                            <v-img class="photo-table" v-else :src="require('@/assets/DEFAULT_IMG.png')" style="max-width: 40px;" cover/>
                        </v-row>
                    </template>

                    <template #[`item.tipo`]="{item}">
                        <v-chip
                            variant="flat"
                            :text="item.tipo"
                            :color="item.tipo == 'PROPIETARIO'? '#732282' : '#E1BEE7'"
                        />
                    </template>

                    <template #[`item.id`]="{item}">
                        <v-btn class="btn" size="small" icon :href="`/candidaturas/${item.id}`">
                            <v-icon>{{ 'mdi-eye' }}</v-icon>
                            <v-tooltip activator="parent" location="top" open-delay="500">Consultar</v-tooltip>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
            </v-row>
        </v-form>
    </v-container>

    <v-snackbar v-model="snackbar" :timeout="2500" :color="color"><p class=ctr>{{ message }}</p></v-snackbar>

    <v-dialog v-model="popup">
    <v-container fluid>
    <v-row justify="center">
        <v-col cols="12" sm="12" md="8" lg="6" xl="4">
            <v-btn icon="mdi-close-thick" size="small" variant="text" color="#FFFFFF" :ripple="false" style="position: fixed; z-index: 50;" @click="popup = false"/>
            <v-img :src="require('@/assets/PopUp.png')" alt="PopUp"></v-img>
        </v-col>
    </v-row>
    </v-container>
    </v-dialog>
</v-main>
</template>

<script>
export default{
    data(){
        return{
            headers: [
                {title: 'FOTOGRAFÍA', key: 'fotografia', align: 'center'},
                {title: 'ACTOR POLÍTICO', key: 'logo', align: 'center'},
                {title: 'CANDIDATURA', key: 'candidatura', align: 'center'},
                {title: 'TIPO', key: 'tipo', align: 'center'},
                {title: 'CARGO', key: 'cargo', align: 'center'},
                {title: 'LOCALIDAD', key: 'localidad', align: 'center'},
                {title: 'PERSONA PROPIETARIA', key: 'nombre_propietario', align: 'center'},
                {title: 'PERSONA SUPLENTE', key: 'nombre_suplente', align: 'center'},
                {titkle: '', key: 'id', align: 'center'}
            ],
            headers_presidencia: [
                {title: 'FOTOGRAFÍA', key: 'fotografia', align: 'center'},
                {title: 'ACTOR POLÍTICO', key: 'logo', align: 'center'},
                {title: 'CANDIDATURA', key: 'candidatura', align: 'center'},
                {title: 'CARGO', key: 'cargo', align: 'center'},
                {title: 'LOCALIDAD', key: 'localidad', align: 'center'},
                {title: 'PERSONA PROPIETARIA', key: 'nombre_propietario', align: 'center'},
                {titkle: '', key: 'id', align: 'center'}
            ],
            busqueda: {
                partido: null,
                candidatura: 2,
                localidad: null,
                genero: null,
                rango_edad: null,
                grado_academico: null
            },
            actores: [],
            localidades: [],
            candidaturas: [
                {id: 2, nombre: 'PRESIDENCIAS MUNICIPALES'},
                {id: 3, nombre: 'DIPUTACIONES DE MR'},
                {id: 4, nombre: 'DIPUTACIONES DE RP'}
            ],
            generos: [{var: 'H', nombre: 'HOMBRE'}, {var: 'M', nombre: 'MUJER'}, {var: 'X', nombre: 'OTRO'}],
            rangos_edad: [],
            grados_academicos: [],
            candidatos: [],
            candidatos_busqueda: [],
            popup: true,
            forms: false,
            data: false,
            snackbar: false,
            message: '',
            color: '',
            search: ''
        }
    },
    methods: {
        onSubmit(){
            if(!this.forms)
                return true;
        },
        notNull(value){
            if(!value)
                return '* Campo obligatorio'
            else
                return true
        },
        limpiar_campos(){
            this.busqueda.partido = '';
            this.busqueda.candidatura = '';
            this.busqueda.localidad = '';
            this.busqueda.genero = '';
            this.busqueda.rango_edad = '';
            this.busqueda.grado_academico = '';
            this.data = false;
        },
        consultar(){
            if(this.busqueda.candidatura == '')
                this.alert('SE REQUIERE DE UNA « CANDIDATURA »', 'red')
            else{
                this.candidatos_busqueda = this.candidatos.filter(this.check_candidatura);

                if(this.busqueda.localidad)
                    this.candidatos_busqueda = this.candidatos_busqueda.filter(this.check_localidad);

                if(this.busqueda.partido)
                    this.candidatos_busqueda = this.candidatos_busqueda.filter(this.check_partido);

                if(this.busqueda.genero)
                    this.candidatos_busqueda = this.candidatos_busqueda.filter(this.check_genero);

                if(this.busqueda.rango_edad)
                    this.candidatos_busqueda = this.candidatos_busqueda.filter(this.check_rango_edad);

                if(this.busqueda.grado_academico)
                    this.candidatos_busqueda = this.candidatos_busqueda.filter(this.check_grado_academico);

                this.data = true;
                this.$nextTick(() => {
                    this.$refs.data_table.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                });
            } 
        },
        check_candidatura(candidato){
            return candidato.candidatura_id == this.busqueda.candidatura;
        },
        check_localidad(candidato){
            return candidato.localidad_id == this.busqueda.localidad;
        },
        check_partido(candidato){
            console.log(candidato.partido_id);
            console.log(this.busqueda.partido);

            return candidato.partido_id == this.busqueda.partido;
        },
        check_genero(candidato){
            return candidato.genero == this.busqueda.genero;
        },
        check_rango_edad(candidato){
            const age = this.calculate_age(candidato.fecha_nacimiento);
            return age >= this.rangos_edad[this.busqueda.rango_edad - 1].rango_num[0] && age <= this.rangos_edad[this.busqueda.rango_edad - 1].rango_num[1];
        },
        check_grado_academico(candidato){
            return candidato.grado_academico == this.busqueda.grado_academico;
        },
        consulta_localidades(){
            if(this.busqueda.candidatura == 2){
                fetch("/jsons/MUNICIPIOS.json")
                .then((response) => response.json())
                .then((response) => {
                    this.localidades = response;
                })
            } else if(this.busqueda.candidatura == 3){
                fetch("/jsons/DISTRITOS.json")
                .then((response) => response.json())
                .then((response) => {
                    this.localidades = response;
                })
            }
        },
        calculate_age(value){
            const f_nacimiento = new Date(value);
            const today = new Date();

            if(f_nacimiento == 'Invalid Date')
                return '**'

            let Age = today.getFullYear() - f_nacimiento.getFullYear();

            if(f_nacimiento.getMonth() > today.getMonth() || (f_nacimiento.getMonth() === today.getMonth() && f_nacimiento.getDate() >= today.getDate()))
                Age--

            return Age;
        },
        alert(mssg, color){
            this.message = mssg;
            this.color = color;
            this.snackbar = true;
        }
    },
    async created(){
        fetch("/jsons/CANDIDATOS.json")
        .then((response) => response.json())
        .then((response) => {
            this.candidatos = response;
        })

        fetch("/jsons/ACTORES_POLITICOS.json")
        .then((response) => response.json())
        .then((response) => {
            this.actores = response;
        })

        fetch("/jsons/RANGOS_EDAD.json")
        .then((response) => response.json())
        .then((response) => {
            this.rangos_edad = response;
        })

        fetch("/jsons/GRADOS_ACADEMICOS.json")
        .then((response) => response.json())
        .then((response) => {
            this.grados_academicos = response;
        })

        fetch("/jsons/MUNICIPIOS.json")
        .then((response) => response.json())
        .then((response) => {
            this.localidades = response;
        })
    }
}
</script>

<style src="@/components/styles/styles.css"/>